import React from 'react';
import { InputPrefix, TextBox, } from '@progress/kendo-react-inputs';
import { searchIcon } from '@progress/kendo-svg-icons';
import { SvgIcon } from '@progress/kendo-react-common';
import "./CampaignAdsList.css"

const CampaignAdsList = () => {
    return (
        <div>

            {/* Ads List Header */}
            <div>
                <h4 > Ads Campaign List</h4>
            </div>

            <div style={{ width: '90%' }}>
                {/* <Input
                    // value={filterValue}
                    // onChange={onFilterChange}
                    placeholder='Search...'
                    style={{
                        border: '2px solid #ccc',
                        boxShadow: 'inset 0px 0px 0.5px 0px rgba(0,0,0,0.0.1)',
                    }}
                /> */}

                <TextBox
                    // value={eventValue}
                    // onChange={handleEventChange}
                    placeholder="Search"
                    prefix={() => (
                        <InputPrefix>
                            <SvgIcon icon={searchIcon} disabled />
                        </InputPrefix>
                    )}
                />
            </div>

            {/* Ads List */}
            <div>
            </div>
        </div>
    );
}

export default CampaignAdsList;
