import * as React from 'react';

import { Link } from "react-router-dom";
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

function Footer() {
  const [visible, setVisible] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("terms");

  const toggleDialog = () => {
    setVisible(!visible);
  };

  return (
    <footer style={{ marginTop: "auto"/*position:"absolute" , left: '0', bottom: '0', right: '0'*/ }}>
      <div className="footer-bottom text-center">
        <div className="container">
          <div className="row" style={{ gap: "12px" }}>
            <div className="col-sm-12 col-lg-4" style={{ margin: "auto" }}>
              <p >
                Empowering the Construction Community
              </p>
            </div>
            <div className="col-sm-12 col-lg-4" style={{ margin: "auto" }}>
              <div className="footer-widget footer-about">
                <ul className="footer-social">
                  <li>
                    <a href="https://www.facebook.com/mybuilderid">
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/mybuilderid">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/mybuilderid/ ">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/my-builder-id/">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:info@mybuiderid.com">
                      <i className="fa fa-envelope"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>



            {/* Terms and Conditions positioned at bottom right */}
            <div className="terms-conditions" onClick={toggleDialog}>
              Terms and Conditions
            </div>
          </div>
        </div>
      </div>

      {visible && (
        <Dialog title=" " onClose={toggleDialog} width="60vw" height="85vh">
          <div style={{ display: "flex", justifyContent: "space-around", marginBottom: "10px", gap: '14px' }}>
            <Button
              style={{
                background: activeTab === "terms" ? "#1b3cb0" : "#E0E0E0",
                color: activeTab === "terms" ? "#FFF" : "#000",
                cursor: "pointer",
              }}
              onClick={() => setActiveTab("terms")}
            >
              Terms and Conditions
            </Button>
            <Button
              style={{
                background: activeTab === "privacy" ? "#1b3cb0" : "#E0E0E0",
                color: activeTab === "privacy" ? "#FFF" : "#000",
                border: "none",
                cursor: "pointer",
              }}
              onClick={() => setActiveTab("privacy")}
            >
              Privacy Policy
            </Button>
          </div>

          {activeTab === "terms" && (
            <iframe
              src="https://www.iubenda.com/terms-and-conditions/90161377"
              width="100%"
              height="90%"
              style={{ border: "none" }}
              title="Terms and Conditions"
            ></iframe>
          )}
          {activeTab === "privacy" && (
            <iframe
              src="https://www.iubenda.com/privacy-policy/90161377"
              width="100%"
              height="90%"
              style={{ border: "none" }}
              title="Privacy Policy"
            ></iframe>
          )}
        </Dialog>)}
    </footer>
  );
}

export default Footer;
