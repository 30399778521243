import React from 'react';
import CampaignAdsList from './CampaignAdsList/CampaignAdsList';
import CampaignAdsDetails from './CampaignAdsDetails/CampaignAdsDetails';

const CampaignAdsHome = () => {
    return (
        <div style={{
            display: 'flex',
            height: "100vh"
        }}>
            <div style={{ backgroundColor: 'lightgray', width: "30vw", padding: "40px" }}>
                <CampaignAdsList />
            </div>
            <div style={{ width: "70vw", padding: "40px" }}>
                <CampaignAdsDetails />
            </div>
        </div>
    );
}

export default CampaignAdsHome;
