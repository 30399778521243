import React from 'react';

const CampaignAdsDetails = () => {
    return (
        <div>
            Details
        </div>
    );
}

export default CampaignAdsDetails;
