import React from 'react';
import logo from "../../assets/images/logo/logo01.png"
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import { Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import { Button } from '@progress/kendo-react-buttons';
import { useNavigate } from "react-router-dom";

const center = {
    lat: 51.048615,
    lng: -114.070847
};

const CampaignSites = () => {
    const navigate = useNavigate();

    return (
        <div className="view-sites-container" >
            <Card style={{ padding: "10px 50px 10px 50px", boxShadow: ' 0 0 8px 2px lightgray' }}>

                <CardTitle >
                    <h1>Campaign Name</h1>
                </CardTitle>

                <CardBody >
                    <div style={{ display: "flex", flexDirection: "column", marginTop: '0px', gap: "12px", height: "50vh", overflow: "auto" }}>

                        <div className="sites-list"><Button>
                            Sites 1
                            <div className='clicked-site-div'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Clicks:</div> <div>33</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Views: </div><div>1234</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Average/ Site: </div><div>22</div></div>
                            </div>
                        </Button></div>
                        <div className="sites-list"><Button>
                            Sites 2
                            <div className='clicked-site-div'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Clicks:</div> <div>33</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Views: </div><div>1234</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Average/ Site: </div><div>22</div></div>
                            </div>
                        </Button></div>
                        <div className="sites-list"><Button>
                            Sites 3
                            <div className='clicked-site-div'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Clicks:</div> <div>33</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Views: </div><div>1234</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Average/ Site: </div><div>22</div></div>
                            </div>
                        </Button></div>
                        <div className="sites-list"><Button>
                            Sites 4
                            <div className='clicked-site-div'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Clicks:</div> <div>33</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Views: </div><div>1234</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Average/ Site: </div><div>22</div></div>
                            </div>
                        </Button></div>
                        <div className="sites-list"><Button>
                            Sites 5
                            <div className='clicked-site-div'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Clicks:</div> <div>33</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Views: </div><div>1234</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Average/ Site: </div><div>22</div></div>
                            </div>
                        </Button></div>
                        <div className="sites-list"><Button>
                            Sites 6
                            <div className='clicked-site-div'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Clicks:</div> <div>33</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Views: </div><div>1234</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Average/ Site: </div><div>22</div></div>
                            </div>
                        </Button></div>
                        <div className="sites-list"><Button>
                            Sites 7
                            <div className='clicked-site-div'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Clicks:</div> <div>33</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Views: </div><div>1234</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Average/ Site: </div><div>22</div></div>
                            </div>
                        </Button></div>
                        <div className="sites-list"><Button>
                            Sites 8
                            <div className='clicked-site-div'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Clicks:</div> <div>33</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Views: </div><div>1234</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Average/ Site: </div><div>22</div></div>
                            </div>
                        </Button></div>
                        <div className="sites-list"><Button>
                            Sites 9
                            <div className='clicked-site-div'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Clicks:</div> <div>33</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Views: </div><div>1234</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Average/ Site: </div><div>22</div></div>
                            </div>
                        </Button></div>
                        <div className="sites-list"><Button>
                            Sites 10
                            <div className='clicked-site-div'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Clicks:</div> <div>33</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Views: </div><div>1234</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Average/ Site: </div><div>22</div></div>
                            </div>
                        </Button></div>
                        <div className="sites-list"><Button>
                            Sites 11
                            <div className='clicked-site-div'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Clicks:</div> <div>33</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Views: </div><div>1234</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Average/ Site: </div><div>22</div></div>
                            </div>
                        </Button></div>
                        <div className="sites-list"><Button>
                            Sites 12
                            <div className='clicked-site-div'>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Clicks:</div> <div>33</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Total Views: </div><div>1234</div></div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}><div>Average/ Site: </div><div>22</div></div>
                            </div>
                        </Button></div>
                    </div>

                    <span style={{ display: "block", border: "solid 2px lightgray", margin: "20px auto 20px auto" }} />

                    <div style={{ display: "flex", gap: "20px" }}>

                        <Button
                            themeColor={'secondary'}
                            onClick={() => navigate("/Campaign-Home")}
                        >
                            Back
                        </Button>
                        <Button
                            themeColor={'primary'}
                            onClick={() => navigate("/Campaign-Home")}
                        >
                            New Site
                        </Button>
                    </div>

                </CardBody>
            </Card>

            <div className="view-sites-map">
                <GoogleMap
                    mapContainerStyle={{
                        width: '45vmax',
                        height: '70vh',
                        borderRadius: "8px",
                        boxShadow: " 0px 0px 8px 2px lightgray"
                    }}
                    center={center}
                    zoom={12}
                // onLoad={onLoad}
                // onUnmount={onUnmount}
                >
                    { /* Child components, such as markers, info windows, etc. */}
                    <Marker position={center} />
                </GoogleMap>
            </div>
        </div>
    );
}

export default CampaignSites;
